import { create } from "zustand";
import {
  Attachment,
  Channel,
  ChannelCategory,
  Comment,
  Conversation,
  InviteCode,
  Member,
  Server,
  Thread,
  User
} from "@/types/entities";
import { ChannelType } from "@key.ai/enum";
import { IMessage, MonetaryTypeDetails } from "@/types/cusom-types";
import { Op } from "quill/core";
import { SearchParamsOption } from "ky";
import { FileListState } from "@/types/thread";
import {
  Accordion as AccordionType,
  CardShowcase,
  HomeType,
  Links,
  PhotoGallery
} from "@/types/homepage";
import { AvatarType } from "@/types";

export type ModalType =
  | "createServer"
  | "invite"
  | "inviteByEmail"
  | "editServer"
  | "members"
  | "createChannel"
  | "editChannel"
  | "messageFile"
  | "createChannelCategory"
  | "editChannelCategory"
  | "createThread"
  | "editThread"
  | "editComment"
  | "introduction"
  | "channelInfo"
  | "channelDescription"
  | "channelAddMember"
  | "conversationAddMember"
  | "confirmation"
  | "editProfile"
  | "editMessage"
  | "guidelineView"
  | "updateFaqs"
  | "inviteGuest"
  | "imgPreview"
  | "videoPreview"
  | "location"
  | "rejectMember"
  | "imageCrop"
  | "avatar"
  | "memberResponses"
  | "generateWithAi"

  // Home Page UI Modals
  | "photoGallery"
  | "toggleList"
  | "externalLinks"
  | "cardShowcase"
  | "ctaAction"
  | "share"
  | "rearrange"
  | "stripeConnect"
  | "stripePayment"
  | "share"
  | "eventPasscode"
  | "cancelRegistration"
  | "deleteAccount"
  | "addEventToCalendar"
  | "imageSelector"
  | "guestAdd";

export interface ModalData {
  server?: Server;
  member?: Member;
  openCroper?: boolean;
  user?: User;
  unsplashType?: string;
  inviteCode?: InviteCode;
  channel?: Channel;
  conversation?: Conversation;
  channelCategory?: ChannelCategory;
  channelType?: ChannelType;
  apiUrl?: string;
  categoryId?: string;
  videoRaw?: string;
  query?: Record<string, any>;

  // Thread Modal Payload
  // form?: FormikProps<any>;
  // editor?: Editor | null;

  thread?: Thread;
  comment?: Comment;

  callback?: (args?: any) => void;
  before?: (args?: any) => Promise<void>;
  after?: (args?: any) => Promise<void>;
  onError?: (args?: any) => void;

  // For message used when type=messageFile
  modalType?: string;
  memberId?: string;
  conversationId?: string;
  queryKey?: string;
  channelId?: string;

  // For confirmation modal
  json?: unknown;
  searchParams?: SearchParamsOption;
  confirmationTitle?: string;
  string?: string;
  method?: string;

  message?: IMessage;
  msgType?: "conversation" | "channel";

  guideline?: Op[];
  banners?: Attachment[];
  visibleFields?: string[];

  faqs?: Omit<AccordionType, "type">;
  gallery?: Omit<PhotoGallery, "type">;
  cardShowcase?: Omit<CardShowcase, "type">;
  externalLinks?: Omit<Links, "type">;
  eventData?: any;
  role?: string | null;
  refetch?: any;

  images?: FileListState[];
  msgProfile?: {
    userId?: string;
    memberId?: string;
    type?: "channel" | "conversation";
    index: number;
  };

  link?: {
    text: string;
    value?: string;
  };

  // For Img Crop
  img?: File;
  for?: "eventBanner" | "profile" | "homePage" | "calendarBanner" | "homeBasicSection";

  share?: {
    link: string;
    title?: string;
    description?: string;
    for?: "Event" | "Community" | "Calendar";
  };

  stripeData?: any;

  sections?: HomeType[];

  avatarType?: AvatarType;
  selectedTicket?: MonetaryTypeDetails | null;
  participationId?: string;
  manageStatus?: (args?: any) => void;

  // FOR DELETE ACCOUNT
  token?: string;
  calendarId?: string;
  alias?: string;
  calendarDetails?: any;

  // For GENERATE WITH AI
  initPromt?: string;
  aiPromtFor?: "event" | "community";
}

interface ModalStore {
  type: ModalType | null;
  data: ModalData;
  isOpen: boolean;
  onOpen: (type: ModalType, data?: ModalData) => void;
  onClose: () => void;
}

export const useModal = create<ModalStore>((set) => ({
  type: null,
  data: {},
  isOpen: false,
  onOpen: (type, data = {}) => set({ type, data, isOpen: true }),
  onClose: () =>
    set({
      type: null,
      isOpen: false
      // data: {}
    })
}));
